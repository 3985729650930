import ReactPlayer from "react-player";
import Footer from "../../../components/footer";
import Header from "../../../components/header";
import Spinner from "../../../components/spinner";
import { useState } from "react";
import TutorialNav from "../components/tutorial_nav";
import { IoArrowBackCircle } from "react-icons/io5";

export default function HowToConnectL2tpClient() {

    const [loading, setLoading] = useState(true);

    return (
        <>
            <div className="flex flex-col h-screen">
                <Header className='flex' />
                <div className="flex-1 mb-5 w-full lg:my-10">
                    <div className='mx-auto w-full lg:max-w-[1024px] sm:px-5'>

                        <div className='text-center mx-auto w-full lg:w-[500px] mb-10'>
                            <h1 className='mt-10 mb-2 text-[30px] leading-tight'>Tutorials</h1>
                            <p>Get started with our service through simple, guided tutorials and make the most of its features.</p>
                        </div>

                        <div className="bg-white rounded-xl py-5 sm:py-8 px-3 sm:px-8 flex flex-row gap-5">

                            <TutorialNav name='ovpn' />

                            <div className="hidden lg:block h-screen min-h-full w-[0.5px] self-stretch bg-gray-300"></div>

                            <div className="grow lg:ml-5">

                                <div className="flex flex-row items-center gap-3">
                                    <IoArrowBackCircle size={30} className="lg:hidden text-blue-500 cursor-pointer"
                                        onClick={() => {
                                            window.location.replace('/tutorials');
                                        }}
                                    />
                                    <h1 className="text-xl font-bold">Configuring OVPN Client on MikroTik</h1>
                                </div>

                                <ul className="list-decimal ml-5 sm:ml-10 mt-8 flex flex-col gap-3">

                                    <li>
                                        <div className="font-bold">Add OVPN Client Interface:</div>
                                        <ul className="list-disc pl-3 sm:pl-8">
                                            <li>Go to PPP {">"} Interface.</li>
                                            <li>Click + and select OVPN Client.</li>
                                        </ul>
                                    </li>

                                    <li>
                                        <div className="font-bold">Configure OVPN Client Settings:</div>
                                        <ul className="list-disc pl-3 sm:pl-8">
                                            <li><span className="font-medium italic">Name</span>: Enter a name for the OVPN client interface.</li>
                                            <li><span className="font-medium italic">Connect To</span>: Enter the OVPN server's IP address or hostname</li>
                                            <li><span className="font-medium italic">Port</span>: <b>1194</b></li>
                                            <li><span className="font-medium italic">Mode</span>: <b>ip</b></li>
                                            <li><span className="font-medium italic">Protocol</span>: <b>tcp</b></li>
                                            <li><span className="font-medium italic">User</span>: Enter the username provided for the SSTP connection.</li>
                                            <li><span className="font-medium italic">Password</span>: Enter the corresponding password.</li>
                                            <li><span className="font-medium italic">Profile</span>: <b>default</b></li>
                                            <li><span className="font-medium italic">Certificate</span>: <b>none</b> <span className="text-xs">(unckecked the <b>Verify Server Certificate</b>)</span></li>
                                            <li><span className="font-medium italic">TLS Version</span>: <b>any</b></li>
                                            <li><span className="font-medium italic">Auth</span>: <b>sha1</b></li>
                                            <li><span className="font-medium italic">Cipher</span>: <b>blowfish 128</b></li>
                                            <li><span className="font-medium italic">Use Peer DNS</span>: <b>yes</b></li>
                                        </ul>
                                    </li>

                                    <li>
                                        <div className="font-bold">Apply and Enable the Interface:</div>
                                        <ul className="list-disc pl-3 sm:pl-8">
                                            <li>Click <span className="font-bold">Apply</span> and <span className="font-bold">OK</span> to save your settings.</li>
                                            <li>Ensure the interface is enabled and connected.</li>
                                        </ul>
                                    </li>

                                </ul>

                            </div>

                        </div>

                    </div>

                </div>

                <Footer />
            </div>
        </>
    )
}
