import ReactPlayer from "react-player";
import Footer from "../../../components/footer";
import Header from "../../../components/header";
import Spinner from "../../../components/spinner";
import { useState } from "react";
import TutorialNav from "../components/tutorial_nav";
import { IoArrowBackCircle } from "react-icons/io5";

export default function HowToConnectL2tpClient() {

    const [loading, setLoading] = useState(true);

    return (
        <>
            <div className="flex flex-col h-screen">
                <Header className='flex' />
                <div className="flex-1 w-full mb-5 lg:my-10">
                    <div className='mx-auto w-full lg:max-w-[1024px] sm:px-5'>

                        <div className='text-center mx-auto w-full lg:w-[500px] mb-10'>
                            <h1 className='mt-10 mb-2 text-[30px] leading-tight'>Tutorials</h1>
                            <p>Get started with our service through simple, guided tutorials and make the most of its features.</p>
                        </div>

                        <div className="bg-white rounded-xl py-5 sm:py-8 px-3 sm:px-8 flex flex-row gap-5">

                            <TutorialNav name='sstp' />

                            <div className="hidden lg:block h-screen min-h-full w-[0.5px] self-stretch bg-gray-300"></div>

                            <div className="grow lg:ml-5">

                                <div className="flex flex-row items-center gap-3">
                                    <IoArrowBackCircle size={30} className="lg:hidden text-blue-500 cursor-pointer"
                                        onClick={() => {
                                            window.location.replace('/tutorials');
                                        }}
                                    />
                                    <h1 className="text-xl font-bold">Configuring SSTP Client on MikroTik</h1>
                                </div>

                                <ul className="list-decimal ml-5 sm:ml-10 mt-8 flex flex-col gap-3">

                                    <li>
                                        <div className="font-bold">Add SSTP Client Interface:</div>
                                        <ul className="list-disc pl-3 sm:pl-8">
                                            <li>Go to PPP {">"} Interface.</li>
                                            <li>Click + and select SSTP Client.</li>
                                        </ul>
                                    </li>

                                    <li>
                                        <div className="font-bold">Configure SSTP Client Settings:</div>
                                        <ul className="list-disc pl-3 sm:pl-8">
                                            <li><span className="font-medium italic">Name</span>: Enter a name for the SSTP client interface.</li>
                                            <li><span className="font-medium italic">Connect To</span>: Enter the SSTP server's IP address or hostname</li>
                                            <li><span className="font-medium italic">Port</span>: <b className="text-blue-500">7744</b></li>
                                            <li><span className="font-medium italic">Proxy</span>: (leave empty)</li>
                                            <li><span className="font-medium italic">Proxy Port</span>: <b>443</b></li>
                                            <li><span className="font-medium italic">Certificate</span>: <b>none</b></li>
                                            <li>
                                                <div><span className="font-medium italic">TLS Version</span>: <b>any</b></div>
                                                <ul className="list-disc text-xs mt-1 ml-2 sm:ml-5 mb-2 flex flex-col gap-1">
                                                    <li>Unchecked <b>Verify Server Certificate</b></li>
                                                    <li>Checked <b>Verify Server Address From Certificate</b></li>
                                                    <li>Unchecked <b>PFS</b></li>
                                                </ul>
                                            </li>
                                            <li><span className="font-medium italic">Ciphers</span>: (leave all unchecked)</li>
                                            <li><span className="font-medium italic">User</span>: Enter the username provided for the SSTP connection.</li>
                                            <li><span className="font-medium italic">Password</span>: Enter the corresponding password.</li>
                                            <li><span className="font-medium italic">Profile</span>: <b>default-encryption</b></li>
                                            <li>
                                                <div><span className="font-medium italic">Keepalive Timeout</span>: <b>60</b></div>
                                                <ul className="list-disc text-xs mt-1 ml-2 sm:ml-5 mb-2 flex flex-col gap-1">
                                                    <li>Unchecked <b>Dial On Demand</b></li>
                                                    <li>Unchecked <b>Add Default Route</b></li>
                                                </ul>
                                            </li>
                                            <li>
                                                <div><span className="font-medium italic">Allow</span>:</div>
                                                <ul className="list-disc text-xs mt-1 ml-2 sm:ml-5 mb-2 flex flex-col gap-1">
                                                    <li>Checked <b>mschap2</b></li>
                                                    <li>Checked <b>mschap1</b></li>
                                                    <li>Checked <b>chap</b></li>
                                                    <li>Checked <b>pap</b></li>
                                                </ul>
                                            </li>
                                        </ul>
                                    </li>

                                    <li>
                                        <div className="font-bold">Apply and Enable the Interface:</div>
                                        <ul className="list-disc pl-3 sm:pl-8">
                                            <li>Click <span className="font-bold">Apply</span> and <span className="font-bold">OK</span> to save your settings.</li>
                                            <li>Ensure the interface is enabled and connected.</li>
                                        </ul>
                                    </li>

                                </ul>


                                <div className="my-10 relative bg-gray-900 h-[360px] w-full">

                                    <div className="w-full h-[360px]">
                                        <ReactPlayer style={{ margin: '0px auto' }} width='100%' /*height={505} width={853}*/ onReady={() => {
                                            setLoading(false);
                                        }} controls={true} url='https://youtu.be/nWGDt_-NKYE' />
                                    </div>
                                    {loading && <div className="absolute inset-0 flex justify-center items-center w-full h-[360px]"><Spinner className='h-[50px] w-[50px] text-white' /></div>}
                                </div>


                            </div>

                        </div>

                    </div>

                </div>

                <Footer />
            </div>
        </>
    )
}
