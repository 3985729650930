import { useEffect } from "react";
import { CiWarning } from "react-icons/ci";

export default function PaymentCanceled() {

  useEffect(()=>{
    setTimeout(()=> {
      window.close();
    }, 3000);
  });
  
  return (<div className='w-screen h-screen text-center flex flex-col bg-white justify-center'>
    <div className='text-center w-full'>

      <div className='bg-orange-100 h-[130px] w-[130px] rounded-full mx-auto text-center items-center justify-center flex flex-row'>
        <CiWarning size={80} className='text-orange-500' />
      </div>
      <div className='text-lg mt-5'>Payment Canceled!</div>
      <div className='mt-5 mb-10 mx-auto px-5 text-sm w-[400px]'>
        Your payment has been canceled. If this was a mistake, you can try again or contact our support team for assistance.
        <br />
      </div>
      <a href='/' className='font-medium text-blue-500'>KPTD.ph</a>
    </div>
  </div>);
}
