

import React, { useEffect, useState } from 'react'
import Loader from '../../../components/loader';
import UserNav from '../components/userNav';
import UserHeader from '../components/userHeader';
import api from "../../../utils/api";
import { errorMessage } from '../../../utils/utils';
import { useParams, useSearchParams } from 'react-router-dom';
import Spinner from '../../../components/spinner';
import moment from 'moment';
import maya from '../../../assets/paymaya-logo.svg';
import gcash from '../../../assets/gcash-logo.svg';
import grab from '../../../assets/grabpay-logo.svg';
import shopee from '../../../assets/shopeepay-logo-2.svg';
import { Dialog, DialogBackdrop, DialogPanel } from '@headlessui/react';
import { FaCheck, FaExclamation, FaExternalLinkSquareAlt, FaQuestion } from 'react-icons/fa';
import { FcMoneyTransfer } from 'react-icons/fc';

export default function UserTransactionItem() {

    const { id } = useParams();
    const [searchParams] = useSearchParams();

    const [pageLoading, setPageLoading] = useState(true);
    const [nav, setNav] = useState(false);
    const [openDialog, setOpenDialog] = useState(false);

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [data, setData] = useState<any>();
    const [payment, setPayment] = useState<any>();


    let intervalCallback: any;
    let isOpenDialog = false;

    useEffect(() => {
        const token = window.localStorage.token;

        if (!token) {
            window.location.replace('/signin');
            return;
        }

        setPageLoading(false);
        getData();

    }, []);

    if (pageLoading) {
        return (<div className='w-screen h-screen text-center flex flex-col bg-white justify-center'><Loader /></div>)
    }

    async function getData() {
        setLoading(true);
        try {
            const res = await api.get(`/remote/transaction/${id}`);
            const json = await res.json();
            if (res.ok) {
                setData(json);
                if (searchParams.get('u') === '1') {
                    checkPayment(json);
                }
            } else {
                setError(errorMessage(json));
            }
        } catch (err) {
            setError(errorMessage(err));
        }
        setLoading(false);
    }

    async function checkPayment(json: any) {
        if (json?.status !== 'WAITING') return;

        setTimeout(() => {
            setPayment({
                url: json?.payment_url,
                status: json?.status?.toLowerCase(),
                message: `Please proceed with the payment. A new tab will open for this process. If it doesn’t, copy and paste the following URL into a new tab.`,
            });
            setOpenDialog(true);
            isOpenDialog = true;
        }, 1000);

        setTimeout(() => window.open(json?.payment_url, '_blank'), 2000);

        setTimeout(() => {
            intervalCallback = setInterval(() => getPaymentStatus(json?.id), 5000);
        }, 10000);
    }

    async function getPaymentStatus(id?: string) {
        if (!id) return;

        try {

            const res = await api.get(`/remote/payment/status/${id}`);
            const json = await res.json();

            if (res.ok) {
                if (json.status !== 'WAITING') {
                    clearInterval(intervalCallback);

                    if (isOpenDialog) {
                        if (json.status === 'SUCCEEDED' || json.status === 'PENDING') {
                            setPayment({
                                status: 'success',
                                message: 'Success! Your payment has been received. Check your purchase/renewal on the Remote Access page.'
                            });
                        } else {
                            setPayment({
                                status: 'failed',
                                message: 'Payment Failed! Please review your payment details for more information.'
                            });
                        }

                    } else {
                        window.location.replace(`/user/transaction/${id}`);
                    }
                }

            } else {
                clearInterval(intervalCallback);
                setPayment({
                    status: 'failed',
                    message: 'Error: Server error.'
                });
            }

        } catch (error) {
            console.trace('get payment error', error);
            clearInterval(intervalCallback);
            setPayment({
                status: 'failed',
                message: 'Error: Server error.'
            });
        }
    }

    function getTransactionDate() {
        if (!data || !data?.date_created) return '';

        try {
            return moment(data.date_created).format('MMM DD YYYY hh:mm:ss a');
        } catch (error) {
            console.log('Error', error);
        }

        return '';
    }

    function bgStatus() {

        let status = 'bg-gray-500';

        switch (data?.status) {
            case 'CREATED':
                status = 'bg-gray-500';
                break;
            case 'WAITING':
                status = 'bg-gray-500';
                break;
            case 'ERROR':
                status = 'bg-red-500';
                break;
            case 'SUCCEEDED':
                status = 'bg-green-500';
                break;
            case 'PENDING':
                status = 'bg-orange-500';
                break;
            case 'FAILED':
                status = 'bg-red-500';
                break;
            case 'VOIDED':
                status = 'bg-gray-500';
                break;
            case 'REFUNDED':
                status = 'bg-blue-500';
                break;
        }

        return status;

    }

    function getPayment() {

        let payment: any;

        switch (data?.payment_method) {
            case 'maya': payment = maya;
                break;
            case 'gcash': payment = gcash;
                break;
            case 'shopeepay': payment = shopee;
                break;
            case 'grabpay': payment = grab;
                break;
        }

        return payment;
    }


    return (
        <>
            <div className='flex flex-row'>
                <div className="hidden xl:block"><UserNav nav='transaction' className='fixed block left-[0px]' /></div>

                <main className='xl:ml-[250px] grow w-screen flex flex-col'>
                    <UserHeader title={`Transaction List > ${data?.id ?? ''}`} setNav={() => { setNav(!nav) }} />
                    <section className="grow w-full">

                        <div className={`px-5 ${!loading && 'hidden'}`}>
                            <div className='mx-auto my-10 text-center'><Spinner className='w-8 h-8 inline text-blue-500 fill-gray-400' /></div>
                        </div>

                        <div className={`m-5 shadow-md bg-white rounded-xl p-5 flex flex-col gap-2 ${(loading || !data || error) && 'hidden'}`}>

                            <div className='font-bold text-sm'>TRANSACTION DETAILS</div>

                            <div>
                                <div className='flex flex-col sm:flex-row sm:items-center mb-2 sm:mb-0'>
                                    <div className='w-[150px] text-sm'>Transaction Date:</div>
                                    <div className='font-medium text-sm sm:text-base'>{getTransactionDate()}</div>
                                </div>

                                <div className='flex flex-col sm:flex-row sm:items-center mb-2 sm:mb-0'>
                                    <div className='w-[150px] text-sm'>Transaction ID:</div>
                                    <div className='font-medium text-sm sm:text-base'>{data?.id ?? ''}</div>
                                </div>

                                <div className='flex flex-row items-center'>
                                    <div className='w-[150px] text-sm'>Transaction Type:</div>
                                    <div className='font-medium'>{data?.action ?? ''}</div>
                                </div>

                                <div className='flex flex-row items-center'>
                                    <div className='w-[150px] text-sm'>Status:</div>
                                    <div className='font-medium'><span className={`${bgStatus()} text-xs rounded-md px-2 py-1 text-white`}>{data?.status?.toUpperCase() ?? ''}</span></div>
                                </div>

                            </div>


                            <div className={`flex flex-row items-center ${!data?.payment_url && 'hidden'}`}>
                                <div className='w-[150px] text-sm'>Payment URL:</div>
                                <a href={data?.payment_url} target="_blank" rel="noreferrer" className={`text-sm text-blue-500 ${!data?.payment_url && 'hidden'}`}>click here to open{/* <FaExternalLinkSquareAlt className="inline" size={15} /> */}</a>
                                {/* <div className='w-full'><a href={data?.payment_url} target='_blank' rel='noreferrer' className='leading-none break-all font-medium text-xs text-blue-500'>{data?.payment_url ?? ''}</a></div> */}
                            </div>

                            <div className={`flex flex-row items-center ${!data?.failure && 'hidden'}`}>
                                <div className='w-[150px] text-sm'>Error:</div>
                                <div className='font-medium'>{(data?.status === 'FAILED' || data?.status === 'ERROR' ? data?.failure ?? 'error' : data?.status)?.toLowerCase()}</div>
                            </div>

                            <hr className='border-[1.5px] my-3' />

                            <div className='font-bold text-sm'>{data?.action?.toUpperCase()} DETAILS</div>

                            <div className={`${data?.action?.toLowerCase() !== 'renew' && 'hidden'}`}>

                                <div className='flex flex-row items-center'>
                                    <div className='w-[150px] text-sm'>Quantity:</div>
                                    <div className='font-medium'>{data?.quantity ?? ''}</div>
                                </div>

                                <div className='flex flex-row items-center'>
                                    <div className='w-[150px] text-sm'>Unit:</div>
                                    <div className='font-medium'>{data?.unit ?? ''}</div>
                                </div>

                                <div className='flex flex-row items-center'>
                                    <div className='w-[150px] text-sm'>Remote:</div>
                                    <div className='font-medium'>
                                        <a className='text-blue-500 hover:underline' href={`/user/remote/${data?.data?.uid}`}>{data?.data?.uid ?? ''}</a>
                                    </div>
                                </div>

                            </div>


                            <div className={`${data?.action?.toLowerCase() !== 'purchase' && 'hidden'}`}>
                                <div className='flex flex-row items-center'>
                                    <div className='w-[150px] text-sm'>Server:</div>
                                    <div className='font-medium'>{data?.data?.server ?? ''}</div>
                                </div>

                                <div className='flex flex-row items-center'>
                                    <div className='w-[150px] text-sm'>Plan:</div>
                                    <div className='font-medium'>{data?.quantity ?? ''} {data?.unit ?? ''}{parseInt(data?.quantity) > 1 && 's'}</div>
                                </div>

                                <div className='flex flex-row items-center'>
                                    <div className='w-[150px] text-sm'>Service:</div>
                                    <div className='font-medium'>{data?.data?.service ?? ''}</div>
                                </div>

                                <div className='flex flex-row items-center'>
                                    <div className='w-[150px] text-sm'>To Port:</div>
                                    <div className='font-medium'>{data?.data?.app_port ?? ''}</div>
                                </div>

                                <div className={`flex flex-row items-center ${data?.data?.app_port !== 'custom' && 'hidden'}`}>
                                    <div className='w-[150px] text-sm'>Custom:</div>
                                    <div className='font-medium'>{data?.data?.custom ?? ''}</div>
                                </div>

                                <div className={`flex flex-row items-center ${data?.data?.app_port !== 'custom' && 'hidden'}`}>
                                    <div className='w-[150px] text-sm'>Protocol:</div>
                                    <div className='font-medium'>{data?.data?.protocol ?? ''}</div>
                                </div>

                                <div className='flex flex-row items-center'>
                                    <div className='w-[150px] text-sm'>Label:</div>
                                    <div className='font-medium'>{data?.data?.label ?? ''}</div>
                                </div>
                            </div>

                            <hr className='border-[1.5px] my-3' />

                            <div className='font-bold text-sm'>PAYMENT DETAILS</div>

                            <div>
                                <div className='flex flex-row items-center'>
                                    <div className='w-[150px] text-sm'>Payment Method:</div>
                                    <div className='mr-2 font-medium'>{data?.payment_method ?? ''}</div>
                                    <img src={getPayment()} alt={data?.payment_method} className="h-[25px] rounded-sm" />
                                </div>

                                <div className='flex flex-row items-center'>
                                    <div className='w-[150px] text-sm'>Subtotal:</div>
                                    <div className='font-medium'>{data?.payment?.subtotal ?? ''}</div>
                                </div>

                                <div className='flex flex-row items-center'>
                                    <div className='w-[150px] text-sm'>Fee:</div>
                                    <div className='font-medium'>{data?.payment?.fee ?? ''}</div>
                                </div>

                                <div className='flex flex-row items-center'>
                                    <div className='w-[150px] text-sm'>Vat:</div>
                                    <div className='font-medium'>{data?.payment?.vat ?? ''}</div>
                                </div>

                                <div className='flex flex-row items-center'>
                                    <div className='w-[150px] text-sm'>Total:</div>
                                    <div className='font-medium'>{data?.payment?.total ?? ''}</div>
                                </div>
                            </div>

                        </div>

                    </section>
                </main>

            </div>

            <div className='xl:hidden'>
                {nav && <div className="bg-black/80 fixed w-full h-screen z-10 top-0 left-0" onClick={() => setNav(false)}></div>}
                <div className={nav ? "fixed top-0 left-0 w-[250px] h-screen bg-white z-10 duration-300" : "fixed top-0 left-[-100%] w-[250px] h-screen bg-white z-10 duration-300"}>
                    <UserNav nav='transaction' />
                </div>
            </div>


            <Dialog open={openDialog} onClose={() => {
                isOpenDialog = false;
            }} className="relative z-10">
                <DialogBackdrop
                    transition
                    className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
                />

                <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                    <div className="flex min-h-full justify-center p-4 text-center items-center">
                        <DialogPanel
                            transition
                            className="w-full sm:w-[450px] py-8 px-5 sm:px-10 relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
                        >

                            <div className={`mx-auto flex h-[100px] w-[100px] flex-shrink-0 items-center justify-center rounded-full ${payment?.status === 'waiting' ? 'bg-blue-50' : payment?.status === 'success' ? 'bg-green-200' : payment?.status === 'failed' ? 'bg-red-200' : 'bg-gray-200'}`}>
                                {!payment?.status && <FaQuestion className="h-[50px] w-[50px] text-gray-500" />}
                                {payment?.status === 'waiting' && <FcMoneyTransfer className="h-[70px] w-[70px]" />}
                                {payment?.status === 'failed' && <FaExclamation className="h-[50px] w-[50px] text-red-500" />}
                                {payment?.status === 'success' && <FaCheck className="h-[50px] w-[50px] text-green-500" />}
                            </div>

                            <div className={`text-center mt-5 ${!payment?.amount && 'hidden'}`}>
                                <div className='text-sm'>Amount to be paid:</div>
                                <div className='text-2xl font-medium'>₱{payment?.amount ?? '0'}</div>
                            </div>

                            <div className="my-5 text-sm text-center text-gray-500 w-full"><div dangerouslySetInnerHTML={{ __html: payment?.message }} /></div>

                            <div className={`text-center mb-5 ${!payment?.url && 'hidden'}`}><a href={payment?.url} target='_blank' rel="noreferrer" className='text-xs text-blue-700 break-all'>{payment?.url}</a></div>

                            <div className='text-center italic text-xs text-gray-400'>You can safely close this dialog and check the status of your payment.</div>

                            <div className="text-center mt-5">
                                <button
                                    type="button"
                                    data-autofocus
                                    onClick={() => {
                                        clearInterval(intervalCallback);
                                        if (payment.status !== 'waiting') {
                                            window.location.replace(`/user/transaction/${id}`);
                                        } else {
                                            isOpenDialog = false;
                                            setOpenDialog(false);
                                        }
                                    }}
                                    className="inline-flex justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                                >Close</button>
                            </div>

                        </DialogPanel>
                    </div>
                </div>
            </Dialog>
        </>
    )
}
