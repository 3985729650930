import { useEffect, useState } from 'react'
import Header from '../../components/header'
import Spinner from '../../components/spinner';
import { errorMessage } from '../../utils/utils';
import api from '../../utils/api';
import ReactCountryFlag from "react-country-flag"
import Footer from '../../components/footer';

export default function Servers() {

    const [loading, setLoading] = useState(true);
    const [servers, setServers] = useState<any[]>([]);
    const [error, setError] = useState('');

    useEffect(() => {
        getServersStatus();
    }, []);


    async function getServersStatus() {
        setError('');

        try {
            const res = await api.get(`/remote/server/status`);
            const json = await res.json();

            if (res.ok) {
                setServers(json);
            } else {
                setError(errorMessage(json))
            }
        } catch (err) {
            setError(errorMessage(err))
        }

        setLoading(false);
    }

    return (
        <div className="flex flex-col h-screen">

            <Header className='flex' />

            <div className=' flex-1 w-full md:my-10'>
                
                <div className='mx-auto w-full md:w-[1024px] h-full flex flex-col items-center'>

                    <h1 className='mt-10 text-[30px] leading-tight'>
                        <span className='font-medium text-blue-600'>Remote Servers</span>
                    </h1>

                    {!loading && !error && <div className='w-full'>
                        <Small servers={servers} className='sm:hidden' />
                        <Large servers={servers} className='hidden sm:block' />
                    </div>}

                    {loading && <div className='flex grow items-center gap-3 justify-center pb-10'><Spinner className='w-[40px] h-[40px]' /><div>Loading...</div></div>}

                    {!loading && error && <div className='flex grow items-center justify-center pb-10 italic text-red-500 text-sm'>{error}</div>}

                </div>
            </div>
            <Footer />
        </div>
    )
}

type Transaction = {
    servers: any[],
    className?: string,
}

function Small({ servers, className }: Transaction) {
    return (
        <div className={`w-full mb-5 sm:px-3 ${className && className}`}>
            <ul className='w-full flex flex-col gap-5 mt-5'>{
                servers?.map((server: any, index: number) => {

                    return <li key={`${index}-server`} className='w-full p-3 text-left flex flex-col bg-white rounded-md shadow-sm'>
                        <div className='font-bold'>{server.label}</div>

                        <div className='flex flex-row items-center'>
                            <div className='grow flex flex-col gap-1'>

                                <div className='text-sm flex flex-row gap-2'>
                                    <span>Status:</span>
                                    <span className={`text-xs h-[17px] flex flex-row items-center justify-center font-medium text-white px-1 rounded-md ${server.status === 'online' ? 'bg-green-500' : 'bg-gray-500'}`}>{server.status}</span>
                                </div>

                                <div className='text-sm flex flex-row gap-2'>
                                    <span>Services:</span>
                                    <span className='font-bold text-blue-500'>{server.services.map((item: any) => item.toUpperCase()).join(', ')}</span>
                                </div>

                                <div className='text-sm flex flex-row gap-2'>
                                    <span>Subscription:</span>
                                    <span className='font-medium'>{server.subscription}</span>
                                </div>

                                <div className='text-sm flex flex-row gap-2'>
                                    <span>Uptime:</span>
                                    <span className='font-medium'>{server.uptime}</span>
                                </div>

                                <div className='text-sm flex flex-row gap-2'>
                                    <span>Location:</span>
                                    <span className='font-medium'>{server.location?.toUpperCase()}</span>
                                </div>

                            </div>
                            <div className='group relative flex justify-center'>
                                <ReactCountryFlag
                                    countryCode={server?.location}
                                    svg
                                    style={{ width: '50px', height: '37px', }}
                                    className='border-[1px] border-gray-300 rounded-sm'
                                />
                                <span className="absolute z-10 top-10 scale-0 transition-all rounded bg-gray-800 p-2 text-xs text-white group-hover:scale-100">{server?.location?.toUpperCase()}</span>
                            </div>
                        </div>

                    </li>
                })
            }</ul>
        </div>
    )
}

function Large({ servers, className }: Transaction) {
    return (
        <div className={`w-full px-5 mt-10 ${className && className}`}>
            <table className='table table-fixed w-full'>
                <tbody>
                    <tr>
                        <td className='w-[50px] text-center text-xs'>Status</td>
                        <td className='w-[60px] text-center text-xs'>Location</td>
                        <td className='text-xs'>Name</td>

                        <td className='w-[130px] text-xs'>
                            <span>Services</span>
                            <span className='md:hidden'> / Uptime</span>
                        </td>

                        <td className='hidden md:table-cell w-[200px] text-center text-xs'>Uptime</td>
                        <td className='hidden md:table-cell w-[100px] text-center text-xs'>Subscription</td>
                        {/* <td className='w-[80px] text-center text-xs'>Ping</td> */}
                    </tr>
                </tbody>
            </table>

            <ul className='flex flex-col gap-5 mt-5'>
                {
                    servers?.map((server: any, index: number) => {

                        let ping = server.ping;

                        if (ping === 'unknown') {
                            ping = 'Timeout';
                        } else {
                            ping = `${parseInt(server?.ping).toFixed(1)} ms`;
                        }

                        return <li key={`${index}-server`} className='rounded-xl bg-white shadow-md'>
                            <table className='table table-fixed w-full'>
                                <tbody>
                                    <tr className='h-[50px]'>
                                        <td className='w-[50px] text-center text-xs'>
                                            <div className='group relative flex justify-center'>
                                                <div className={`w-[15px] h-[15px] rounded-full mx-auto ${server?.status === 'online' ? 'bg-green-500' : 'bg-gray-500'}`}></div>
                                                <span className="absolute z-10 top-10 scale-0 transition-all rounded bg-gray-800 p-2 text-xs text-white group-hover:scale-100">{server?.status ?? 'offline'}</span>
                                            </div>
                                        </td>
                                        <td className='text-center w-[60px]'>
                                            <div className='group relative flex justify-center'>
                                                <ReactCountryFlag
                                                    countryCode={server?.location}
                                                    svg
                                                    style={{ width: '30px', height: '22px', }}
                                                    className='border-[1px] border-gray-300 rounded-sm'
                                                />
                                                <span className="absolute z-10 top-10 scale-0 transition-all rounded bg-gray-800 p-2 text-xs text-white group-hover:scale-100">{server?.location?.toUpperCase()}</span>
                                            </div>
                                        </td>
                                        <td className='text-sm md:text-md text-left px-2 font-medium'>{server?.label}</td>
                                        <td className='w-[130px] text-center text-xs'>
                                            <div>{server.services.map((item: any) => item.toUpperCase()).join(', ')}</div>
                                            <div className='md:hidden mt-1'>{server.uptime}</div>
                                        </td>
                                        <td className='hidden md:table-cell w-[200px] text-center text-xs'>{server.uptime}</td>
                                        <td className='hidden md:table-cell w-[100px] text-center text-xs'><span className='rounded-md text-white bg-gray-500 px-2 py-1'>{server?.subscription}</span></td>
                                        {/* <td className='w-[80px] text-center text-xs'>{ping}</td> */}
                                    </tr>
                                </tbody>
                            </table>
                        </li>
                    })
                }
            </ul>
        </div>
    )
}
