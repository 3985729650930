import ReactPlayer from "react-player";
import Footer from "../../../components/footer";
import Header from "../../../components/header";
import Spinner from "../../../components/spinner";
import { useState } from "react";
import TutorialNav from "../components/tutorial_nav";
import { IoArrowBackCircle } from "react-icons/io5";

export default function HowToConnectL2tpClient() {

    const [loading, setLoading] = useState(true);

    return (
        <>
            <div className="flex flex-col h-screen">
                <Header className='flex' />
                <div className="flex-1 w-full mb-5 lg:my-10">
                    <div className='mx-auto w-full lg:max-w-[1024px] sm:px-5'>

                        <div className='text-center mx-auto w-full lg:w-[500px] mb-10'>
                            <h1 className='mt-10 mb-2 text-[30px] leading-tight'>Tutorials</h1>
                            <p>Get started with our service through simple, guided tutorials and make the most of its features.</p>
                        </div>

                        <div className="bg-white rounded-xl py-5 sm:py-8 px-3 sm:px-8 flex flex-row gap-5">

                            <TutorialNav name='connect' />

                            <div className="hidden lg:block h-screen min-h-full w-[0.5px] self-stretch bg-gray-300"></div>


                            <div className="grow sm:ml-5">

                                <div className="flex flex-row items-center gap-3">
                                    <IoArrowBackCircle size={30} className="lg:hidden text-blue-500 cursor-pointer"
                                        onClick={() => {
                                            window.location.replace('/tutorials');
                                        }}
                                    />
                                    <h1 className="text-xl font-bold">How to remotely access MikroTik using Winbox</h1>
                                </div>

                                <div className="my-10 relative bg-gray-900 h-[360px] w-full">
                                    <div className="w-full h-[360px]">
                                        <ReactPlayer style={{ margin: '0px auto' }} width='100%' /*height={505} width={853}*/ onReady={() => {
                                            setLoading(false);
                                        }} controls={true} url='https://youtu.be/mUxd-WALAcM' />
                                    </div>
                                    {loading && <div className="absolute inset-0 flex justify-center items-center w-full h-[360px]"><Spinner className='h-[50px] w-[50px] text-white' /></div>}
                                </div>

                            </div>

                        </div>

                    </div>

                </div>

                <Footer />
            </div>
        </>
    )
}
