

import { useEffect, useState } from 'react'
import Loader from '../../../../components/loader';
import UserNav from '../../components/userNav';
import UserHeader from '../../components/userHeader';
import { Bounce, toast, ToastContainer } from 'react-toastify';
import api from '../../../../utils/api';
import { errorMessage } from '../../../../utils/utils';
import Spinner from '../../../../components/spinner';
import ReactCountryFlag from 'react-country-flag';
import mikrotik from '../../../../assets/mikrotik.svg';
import adopisoft from '../../../../assets/adopisoft.png';
import gcash from '../../../../assets/gcash-logo.svg';
import maya from '../../../../assets/paymaya-logo.svg';
import shopee from '../../../../assets/shopeepay-logo-2.svg';
import grab from '../../../../assets/grabpay-logo.svg';
import moment from 'moment';
import { Dialog, DialogBackdrop, DialogPanel, DialogTitle } from '@headlessui/react';
import { FaExclamation, FaQuestion } from 'react-icons/fa';

const monthly = 40;
const yearly = 360;

export default function RemoteNew() {

    useEffect(() => {

        if (!window.localStorage.token) {
            window.location.replace('/signin');
            return;
        }

        setPageLoading(false);

        getServers();

    }, []);

    const [pageLoading, setPageLoading] = useState(true);
    const [nav, setNav] = useState(false);

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [errorPayment, setErrorPayment] = useState(false);
    const [errorService, setErrorService] = useState(false);
    const [errorSubsPlan, setErrorSubsPlan] = useState(false);
    const [errorPort, setErrorPort] = useState(false);
    const [errorCustom, setErrorCustom] = useState(false);
    const [errorProtocol, setErrorProtocol] = useState(false);
    const [errorLabel, setErrorLabel] = useState(false);

    const [serverLoading, setServerLoading] = useState(true);
    const [serverError, setServerError] = useState('');

    const [servers, setServers] = useState<any[]>([]);
    const [services, setServices] = useState<any[]>([]);
    const [subscription, setSubscription] = useState('');
    const [payment, setPayment] = useState('');

    const [server, setServer] = useState('');
    const [quantity, setQuantity] = useState('1');
    
    const [unit, setUnit] = useState('');
    const [service, setService] = useState('');

    const [app_port, setAppPort] = useState('');
    const [custom, setCustom] = useState('');
    const [protocol, setProtocol] = useState('');

    const [label, setLabel] = useState('');

    const [expiration, setExpiration] = useState<any>();

    const [confirmDialog, setConfirmDialog] = useState(false);
    const [purchaseError, setPurchaseError] = useState('');


    async function getServers() {
        try {
            const res = await api.get(`/remote/server?subs=free,paid`);
            const json = await res.json();
            if (res.ok) {
                setServers(json);

                var find = json.find((item: any) => item.name === 'sg1');

                if (find) {
                    setServer(find.name);
                    setServices(find.services);
                    setSubscription(find.subscription);
                    // setPlan('yearly');
                    setUnit('year');
                    setService('ovpn');
                    getExpirationDate('1', 'yearly');
                    setAppPort('8291');
                }
            } else {
                // toast.error(errorMessage(json));
                setServerError(errorMessage(json));
            }
        } catch (err) {
            // toast.error(errorMessage(err));
            setServerError(errorMessage(err));
        }

        setServerLoading(false);
    }


    if (pageLoading) {
        return (<div className='w-screen h-screen text-center flex flex-col bg-white justify-center'><Loader /></div>)
    }


    function getExpirationDate(quantity: string, unit: string) {
        var now = new Date();
        const exp = new Date(now);

        switch (unit) {
            case 'month':
                exp.setMonth(now.getMonth() + parseInt(quantity));
                break;
            case 'year':
                exp.setFullYear(now.getFullYear() + parseInt(quantity));
                break;
        }

        setExpiration(moment(exp).format('MMMM Do YYYY'));
    }

    function total(): any {

        const json: any = { total: '0', fee: '0', vat: '0', subtotal: '0' };

        if (!subscription) return json;
        if (subscription !== 'paid') return json;
        // if (!subscription) return json;
        // if (!payment) return json;

        let fee = 0;
        let vat = 0;
        let subtotal = 0;
        //gcash 2.3%
        //maya 1.8%
        //shopeepay 2.0%
        //grabpay 2.0%

        const multiplier = isNaN(parseInt(quantity)) ? 0 : parseInt(quantity);

        switch (unit) {
            case 'month':
                subtotal = monthly * multiplier;
                break;
            case 'year':
                subtotal = yearly * multiplier;
                break;
        }

        switch (payment) {
            case 'gcash':
                fee = subtotal * 0.023;
                break;
            case 'maya':
                fee = subtotal * 0.018;
                break;
            case 'shopeepay':
                fee = subtotal * 0.02;
                break;
            case 'grabpay':
                fee = subtotal * 0.02;
                break;
        }


        json.subtotal = Math.ceil(subtotal).toLocaleString();
        json.fee = Math.ceil(fee).toLocaleString();
        json.vat = Math.ceil(vat).toLocaleString();

        json.total = Math.ceil(subtotal + fee + vat).toLocaleString();

        return json;

    }


    function handleSubmit(e: any) {
        e.preventDefault();

        if ((!quantity || !unit) && subscription === 'paid') {
            setErrorSubsPlan(true);
            setError('Please select subscription plan');
            return;
        } else {
            setErrorSubsPlan(false);
        }

        if (!service) {
            setErrorService(true);
            setError('Please select a service');
            return;
        } else {
            setErrorService(false);
        }

        if (!app_port) {
            setErrorPort(true);
            setError('Please select a port');
            return;
        } else {
            setErrorPort(false);
        }

        if (app_port === 'custom') {
            let missing = false;

            if (!custom) {
                setErrorCustom(true);
                missing = true;
            } else {
                setErrorCustom(false);
            }

            if (!protocol) {
                setErrorProtocol(true);
                missing = true;
            } else {
                setErrorProtocol(false);
            }

            if (missing) {
                setError('Please select a port or protocol in custom port');
                return;
            }

        } else {
            setErrorCustom(false);
            setErrorProtocol(false);
        }


        if (!label) {
            setErrorLabel(true);
            setError('Please enter a label');
            return;
        } else {
            setErrorLabel(false);
        }


        if (subscription === 'paid' && !payment) {
            setErrorPayment(true);
            setError('Please select a payment method.');
            return;
        } else {
            setErrorPayment(false);
        }

        setError('');
        setPurchaseError('');

        setConfirmDialog(true);
    }

    async function purchase() {
        setLoading(true);
        try {

            const res = await api.post(`/remote`, { server, quantity, unit, service, app_port, custom, protocol, label, payment, });
            const json = await res.json();

            if (res.ok) {
                if (subscription === 'paid') {
                    //Go To transaction page with the id
                    window.location.replace(`/user/transaction/${json.id}?u=1`);
                } else {
                    //Go To Remote Access page
                    window.location.replace('/user/remote');
                }
                return;
            } else {
                setPurchaseError(errorMessage(json));
            }

        } catch (error) {
            setPurchaseError(errorMessage(error));
        }

        setLoading(false);

    }


    return (
        <>
            <div className='flex flex-row w-full'>

                <div className="hidden xl:block"><UserNav nav='remote' className='fixed block left-[0px]' /></div>
                <main className='xl:ml-[250px] grow w-screen flex flex-col'>
                    <UserHeader title={`My Remote Access > New`} setNav={() => { setNav(!nav) }} />

                    <section className='p-5'>

                        <div className={`w-full px-5 text-center ${!serverLoading && 'hidden'}`}>
                            <div className='mx-auto text-center'>
                                <Spinner className='w-8 h-8 inline text-blue-500 fill-gray-400' />
                                <div className='text-sm mt-2'>Loading servers...</div>
                            </div>
                        </div>

                        <div className={`w-full bg-white rounded-xl p-5 ${(serverLoading || serverError) && 'hidden'}`}>
                            <div className='flex flex-col lg:flex-row'>
                                <form id="form-remote" onSubmit={handleSubmit} className='flex-1'>
                                    <div>
                                        <div className='font-medium'>Select your remote server</div>
                                        <ul className='ml-5 mt-5 flex flex-col gap-3'>
                                            {servers?.map((item: any, index: number) => {
                                                return <li key={`${index}-server`} className={`flex flex-row items-center gap-2 border-[1px] w-[280px] py-2 rounded-md px-2 ${item.name === server ? 'border-[2px] border-blue-500' : 'border-gray-300'} ${item.enabled && 'cursor-pointer'}`}
                                                    onClick={ item.enabled ? () => {
                                                        if (item.name === server) {
                                                            setServer('');
                                                            setSubscription('');
                                                            setUnit('');
                                                            setService('');
                                                            setServices([]);
                                                            setAppPort('');
                                                            setCustom('');
                                                            setProtocol('');
                                                            setQuantity('');
                                                            setExpiration('');
                                                            setPayment('');
                                                        } else {
                                                            setServer(item.name);
                                                            setSubscription(item.subscription);
                                                            setServices(item.services);
                                                            setService('ovpn');
                                                            if (item.subscription === 'paid') {
                                                                setUnit('year');
                                                                setQuantity('1');
                                                            } else {
                                                                setQuantity('');
                                                                setUnit('');
                                                                setPayment('');
                                                                setErrorPayment(false);
                                                            }
                                                            getExpirationDate('1', 'yearly');
                                                        }
                                                    } : undefined}
                                                >

                                                    <div className='group relative flex justify-center'>
                                                        <ReactCountryFlag
                                                            countryCode={item?.location}
                                                            svg
                                                            style={{ width: '30px', height: '22px', }}
                                                            className={`border-[1px] border-gray-300 rounded-sm ${!item.enabled && 'grayscale'}`}
                                                        />
                                                        <span className="absolute z-10 top-10 scale-0 transition-all rounded bg-gray-800 p-2 text-xs text-white group-hover:scale-100">{item?.location?.toUpperCase()}</span>
                                                    </div>

                                                    <div className={`flex flex-col ${!item.enabled && 'text-gray-400'}`}>
                                                        <div className={`leading-none`}>{item.label}</div>
                                                        <div className={`text-xs ${item.enabled && 'hidden'}`}>(unavailable)</div>
                                                    </div>
                                                </li>
                                            })}
                                        </ul>
                                    </div>

                                    <div className={`${(!server || subscription !== 'paid') && 'hidden'}`}>
                                        <hr className='my-8 border-[1.5px] lg:mr-10' />
                                        <div className='font-medium'>Select your subscription plan:</div>
                                        <div className={`text-xs text-red-500 font-medium ${!errorSubsPlan && 'hidden'}`}>* Please select subscription plan</div>
                                        <div className='ml-5 mt-3 flex flex-row items-center gap-3'>
                                            <input
                                                type="number"
                                                min={1}
                                                value={quantity}
                                                style={{ width: "100px", height: '40px' }}
                                                disabled={loading}
                                                aria-describedby="helper-text-explanation"
                                                className="px-3 py-1 border-[1px] border-gray-300 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 disabled:bg-gray-50"
                                                // placeholder="1"
                                                onChange={(e) => {
                                                    if (e.target.value === '0') return;
                                                    setQuantity(e.target.value);
                                                    getExpirationDate(e.target.value, unit);
                                                    if (unit) {
                                                        setErrorSubsPlan(false);
                                                    }
                                                }}
                                            />
                                            <select
                                                value={unit}
                                                style={{ width: "150px", height: '40px' }}
                                                disabled={loading}
                                                className={`px-2 py-1 border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500 disabled:bg-gray-50`}
                                                onChange={(e) => {
                                                    setUnit(e.target.value);
                                                    getExpirationDate(quantity, e.target.value);
                                                    if (quantity) {
                                                        setErrorSubsPlan(false);
                                                    }
                                                }}>
                                                <option disabled={true}>Please select...</option>
                                                <option value='month'>Month{parseInt(quantity) > 1 ? 's' : ''}</option>
                                                <option value='year'>Year{parseInt(quantity) > 1 ? 's' : ''}</option>
                                            </select>
                                        </div>
                                        <div className='ml-5 mt-2 flex flex-row items-center gap-2'>
                                            <div className='text-xs'>Estimated date expiration:</div>
                                            <div className='text-xs font-medium'>{expiration}</div>
                                        </div>
                                    </div>

                                    <div className={`${services.length === 0 && 'hidden'}`}>
                                        <hr className='my-8 border-[1.5px] lg:mr-10' />
                                        <div className='font-medium'>Select your service</div>
                                        <div className={`text-xs text-red-500 font-medium ${!errorService && 'hidden'}`}>* Please select a service</div>
                                        {/* <div className='ml-5 text-xs'>Services may vary depending on the supported devices.</div> */}
                                        <ul className='ml-5 mt-3 mb-5 flex flex-row items-center gap-3'>

                                            {services.map((item: any, index: number) => {
                                                return <li key={`${index}-service`} className={`text-center border-[1px] py-1 rounded-md px-1 cursor-pointer w-[80px] ${item === service ? 'border-[2px] border-blue-500' : 'border-gray-300'}`}
                                                    onClick={() => {
                                                        if (item === service) {
                                                            setService('');
                                                            setAppPort('');
                                                            setCustom('');
                                                            setProtocol('');
                                                        } else {
                                                            setService(item);
                                                            setAppPort('');
                                                            setErrorService(false);
                                                        }
                                                    }}
                                                >
                                                    {item.toUpperCase()}
                                                </li>
                                            })}
                                        </ul>
                                        <div className={`ml-5 mt-3 flex flex-col sm:flex-row sm:items-center gap-2 ${!service && 'hidden'}`}>
                                            <div className='text-xs'>Supported devices:</div>

                                            <div>
                                                <img src={mikrotik} alt="Mikrotik" className={`h-[20px]`} />
                                            </div>

                                            <div>
                                                <img src={adopisoft} alt="Adopisoft" className={`h-[20px] ${service !== 'ovpn' && 'hidden'}`} />
                                            </div>

                                        </div>


                                    </div>

                                    <div className={`${!service && 'hidden'}`}>
                                        <hr className='my-8 border-[1.5px] lg:mr-10' />
                                        <div className='font-medium'>To Port:</div>
                                        <div className='ml-5 text-xs lg:mr-10'>The port you want to remote. (e.g. if you want to remote from winbox use 8291, if you want to remote adopisoft use 443)</div>
                                        <div className='ml-5 mt-3'>
                                            <select
                                                value={app_port}
                                                style={{ width: "250px", height: '40px' }}
                                                disabled={loading}
                                                className={`px-2 py-1 border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500 disabled:bg-gray-50`}
                                                onChange={(e) => {
                                                    setAppPort(e.target.value);

                                                    if (e.target.value !== 'custom') {
                                                        setCustom('');
                                                        setProtocol('');
                                                    }

                                                    if (e.target.value) {
                                                        setErrorPort(false);
                                                    }

                                                }}>
                                                <option value=''>Select...</option>
                                                <option value='8291'>Winbox (8291)</option>
                                                <option value='443'>HTTPS (443)</option>
                                                <option value='80'>HTTP (80)</option>
                                                <option value='custom'>Others</option>
                                            </select>
                                            <div className={`text-xs font-medium text-red-500 ${!errorPort && 'hidden'}`}>* Please select a port</div>
                                        </div>
                                        <div className={`ml-5 mt-3 flex flex-row gap-3 ${app_port !== 'custom' && 'hidden'}`}>
                                            <div className="mb-2 flex flex-col">
                                                <label htmlFor="custom" className="block text-sm font-medium leading-6 text-gray-900">Port</label>
                                                <div className='grow'>
                                                    <input
                                                        type="text"
                                                        // required={app_port === 'custom' ? true : false}
                                                        value={custom}
                                                        disabled={loading}
                                                        maxLength={5}
                                                        style={{ width: "118px", height: '40px' }}
                                                        placeholder="0 to 65535"
                                                        className="px-3 block w-full rounded-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 disabled:bg-gray-50 placeholder:text-sm placeholder:text-gray-400"
                                                        onChange={(e) => {
                                                            const re = /^[0-9\b]+$/;
                                                            if (e.target.value === '' || re.test(e.target.value)) {
                                                                setCustom(e.target.value);

                                                                if (e.target.value) {
                                                                    setErrorCustom(false);
                                                                }
                                                            }
                                                        }}
                                                    />
                                                    <div className={`text-xs font-medium text-red-500 w-[118px] ${!errorCustom && 'hidden'}`}>* Enter a port</div>
                                                </div>
                                            </div>

                                            <div className="mb-2 flex flex-col">
                                                <label htmlFor="protocol" className="block text-sm font-medium leading-6 text-gray-900">Protocol</label>
                                                <select
                                                    // required={app_port === 'custom' ? true : false}
                                                    value={protocol}
                                                    disabled={loading}
                                                    style={{ width: "118px", height: '40px' }}
                                                    className={`h-8 px-2 py-1 border border-gray-300 text-sm rounded focus:ring-blue-500 focus:border-blue-500 disabled:bg-gray-50`}
                                                    onChange={(e) => {
                                                        setProtocol(e.target.value);
                                                        if (e.target.value) {
                                                            setErrorProtocol(false);
                                                        }
                                                    }}>
                                                    <option value=''>Select...</option>
                                                    <option value='tcp'>TCP</option>
                                                    <option value='udp'>UDP</option>
                                                </select>
                                                <div className={`text-xs font-medium text-red-500 ${!errorProtocol && 'hidden'}`}>* Enter a protocol</div>
                                            </div>
                                        </div>

                                    </div>

                                    <div className={`lg:mb-5 ${!app_port && 'hidden'}`}>
                                        <hr className='my-8 border-[1.5px] lg:mr-10' />
                                        <div className='font-medium'>Remote label:</div>
                                        <div className={`text-xs font-medium text-red-500 ${!errorLabel && 'hidden'}`}>* Please enter a label</div>
                                        <div className='ml-5 mt-3'>
                                            <input
                                                type="text"
                                                value={label}
                                                disabled={loading}
                                                style={{ width: "285px" }}
                                                placeholder="e.g. Mikrotik hEX"
                                                className="px-3 block w-full rounded-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 disabled:bg-gray-50 placeholder:text-sm placeholder:text-gray-400"
                                                onChange={(e) => {
                                                    setLabel(e.target.value);
                                                    if (e.target.value) {
                                                        setErrorLabel(false);
                                                    }
                                                }}
                                            />
                                        </div>

                                    </div>
                                </form>

                                <div className={`flex-1 flex flex-col gap-2 mb-5 ${!server && 'hidden'}`}>
                                    <hr className='lg:hidden my-10 border-[1.5px]' />
                                    <div className='font-medium mb-3'>Purchase Details:</div>

                                    <div className='flex flex-row items-center gap-2'>
                                        <div className='w-[120px]'>Server:</div><div className='font-bold'>{servers.find((item: any) => item.name === server)?.label}</div>
                                    </div>

                                    <div className='flex flex-row items-center gap-2'>
                                        <div className='w-[120px]'>Subscription:</div><div className='font-bold'>{subscription}</div>
                                    </div>

                                    <div className='flex flex-row items-center gap-2'>
                                        <div className='w-[120px]'>Plan:</div><div className='font-bold'>{quantity} {unit}{`${parseInt(quantity) > 1 ? 's' : ''}`}</div>
                                    </div>

                                    <div className='flex flex-row items-center gap-2'>
                                        <div className='w-[120px]'>Expiration:</div><div className='font-bold'>{expiration}</div>
                                    </div>

                                    <div className='flex flex-row items-center gap-2'>
                                        <div className='w-[120px]'>Service:</div><div className='font-bold'>{service}</div>
                                    </div>

                                    <div className='flex flex-row items-center gap-2'>
                                        <div className='w-[120px]'>To Port:</div><div className='font-bold'>{app_port === 'custom' ? custom ? `${protocol}${protocol ? ':' : ''}${custom}` : '' : app_port}</div>
                                    </div>

                                    <div className='flex flex-row items-center gap-2'>
                                        <div className='w-[120px]'>Label:</div><div className='font-bold'>{label}</div>
                                    </div>

                                    <hr className='border-[1.5px] my-5' />

                                    <div className='mb-3'>
                                        <div className='font-medium'>Select your payment method:</div>
                                        <div className={`text-xs text-red-500 font-medium ${!errorPayment && 'hidden'}`}>* Please select payment method</div>
                                        <div className={`flex flex-row items-center gap-2 ${!payment && 'hidden'}`}>
                                            <div className='text-xs'>You have selected <span className='font-bold'>{payment.toUpperCase()}</span> as your payment method</div>
                                        </div>
                                    </div>

                                    <div className='flex flex-row gap-3'>
                                        <button disabled={subscription !== 'paid' || loading} type="button" onClick={() => {
                                            setPayment(payment === 'gcash' ? '' : 'gcash');
                                            setErrorPayment(false);
                                        }}>
                                            <div className='group relative flex justify-center'>
                                                <img src={gcash} alt="GCASH" className={`h-[35px] rounded-md border-[2px] ${payment === 'gcash' ? 'border-green-500' : 'border-transparent'}`} />
                                                <span className="absolute z-10 top-10 scale-0 transition-all rounded bg-gray-800 p-2 text-xs text-white group-hover:scale-100 whitespace-nowrap">GCash</span>
                                            </div>
                                        </button>
                                        <button disabled={subscription !== 'paid' || loading} type="button" onClick={() => {
                                            setPayment(payment === 'maya' ? '' : 'maya');
                                            setErrorPayment(false);
                                        }}>
                                            <div className='group relative flex justify-center'>
                                                <img src={maya} alt="MAYA" className={`h-[40px] rounded-md border-[2px] ${payment === 'maya' ? 'border-green-500' : 'border-transparent'}`} />
                                                <span className="absolute z-10 top-10 scale-0 transition-all rounded bg-gray-800 p-2 text-xs text-white group-hover:scale-100 whitespace-nowrap">Maya</span>
                                            </div>
                                        </button>
                                        <button disabled={subscription !== 'paid' || loading} type="button" onClick={() => {
                                            setPayment(payment === 'shopeepay' ? '' : 'shopeepay');
                                            setErrorPayment(false);
                                        }}>
                                            <div className='group relative flex justify-center'>
                                                <img src={shopee} alt="SHOPEEPAY" className={`h-[35px] rounded-md border-[2px] ${payment === 'shopeepay' ? 'border-green-500' : 'border-transparent'}`} />
                                                <span className="absolute z-10 top-10 scale-0 transition-all rounded bg-gray-800 p-2 text-xs text-white group-hover:scale-100 whitespace-nowrap">ShopeePay</span>
                                            </div>
                                        </button>
                                        <button disabled={true} type="button" onClick={() => {
                                            setPayment(payment === 'grabpay' ? '' : 'grabpay');
                                            setErrorPayment(false);
                                        }}>
                                            <div className='group relative flex justify-center'>
                                                <img src={grab} alt="GRABPAY" className={`grayscale h-[35px] rounded-md border-[2px] ${payment === 'grabpay' ? 'border-green-500' : 'border-transparent'}`} />
                                                <span className="absolute z-10 top-10 scale-0 transition-all rounded bg-gray-800 p-2 text-xs text-white group-hover:scale-100 whitespace-nowrap">GrabPay</span>
                                            </div>
                                        </button>
                                    </div>


                                    <hr className='border-[1.5px] my-5' />

                                    <div className='font-medium mb-3'>Payment Details:</div>

                                    <div className='flex flex-row items-center gap-2'>
                                        <div className='w-[120px] text-xs'>Payment Method:</div><div className='font-bold'>{payment.toUpperCase()}</div>
                                    </div>

                                    <div className='flex flex-row items-center gap-2'>
                                        <div className='w-[120px]'>Subtotal:</div><div className='font-bold'>₱{total()?.subtotal}</div>
                                    </div>

                                    <div className='flex flex-row items-center gap-2'>
                                        <div className='w-[120px]'>Service fee:</div><div className='font-bold'>₱{total()?.fee}</div>
                                    </div>

                                    <div className='flex flex-row items-center gap-2'>
                                        <div className='w-[120px]'>Vat:</div><div className='font-bold'>₱{total()?.vat}</div>
                                    </div>

                                    <div className='flex flex-row items-center gap-2'>
                                        <div className='w-[120px]'>Total:</div><div className='text-blue-500 text-xl font-bold'>₱{total()?.total}</div>
                                    </div>

                                    <div className='mt-2 text-xs'>A confirmation receipt will be sent to <span className='font-bold'>{window.localStorage['email']}</span></div>


                                </div>
                            </div>

                            <div className={`${!server && 'hidden'}`}>
                                <hr className='border-[1.5px] my-5' />
                                <div className={`mb-5 text-center text-red-500 italic text-sm mt-3 ${!error && 'hidden'}`} dangerouslySetInnerHTML={{ __html: error }} />
                                <div className='flex flex-row items-center'>
                                    <button type="button" className='border-[1px] border-gray-400 px-3 py-2 rounded-md shadow-md hover:bg-gray-200 active:bg-gray-300'
                                        onClick={() => window.history.back()}
                                    >Back</button>
                                    <span className='grow'></span>
                                    <button type="submit" form='form-remote' className='bg-green-500 text-white px-3 py-2 rounded-md shadow-md hover:bg-green-600 active:bg-green-500'>Submit</button>
                                </div>
                            </div>
                        </div>

                    </section>
                </main>

            </div>


            <ToastContainer
                position="bottom-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick={true}
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
                transition={Bounce}
            />

            <ConfirmDialog open={confirmDialog} setDialog={setConfirmDialog} onClickYes={purchase} loading={loading} error={purchaseError} />

            <div className='xl:hidden'>
                {nav && <div className="bg-black/80 fixed w-full h-screen z-10 top-0 left-0" onClick={() => setNav(false)}></div>}
                <div className={nav ? "fixed top-0 left-0 w-[250px] h-screen bg-white z-10 duration-300" : "fixed top-0 left-[-100%] w-[250px] h-screen bg-white z-10 duration-300"}>
                    <UserNav nav='remote' />
                </div>
            </div>
        </>
    )
}


function ConfirmDialog({ open, setDialog, onClickYes, loading, error }: any) {
    return (<Dialog open={open ?? false} onClose={() => {
        if (!loading && !error) {
            if (setDialog) {
                setDialog(false);
            }
        }
    }} className="relative z-10">
        <DialogBackdrop
            transition
            className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
        />

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
            <div className="flex min-h-full items-center justify-center text-center p-0">
                <DialogPanel
                    transition
                    className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:my-8 sm:w-full sm:max-w-lg data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95"
                >
                    <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                        <div className="sm:flex sm:items-start">

                            <div className={`mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-blue-200 sm:mx-0 sm:h-10 sm:w-10 ${error && 'hidden'}`}>
                                <FaQuestion aria-hidden="true" className="h-6 w-6 text-blue-700" />
                            </div>

                            <div className={`mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-200 sm:mx-0 sm:h-10 sm:w-10 ${!error && 'hidden'}`}>
                                <FaExclamation aria-hidden="true" className="h-6 w-6 text-red-700" />
                            </div>

                            <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                                <DialogTitle as="h3" className="text-base font-semibold leading-6 text-gray-900">{error ? 'Purchase Error' : 'Confirm Purchase'}</DialogTitle>
                                <div className="mt-2">
                                    <div className="text-sm text-gray-700"><div dangerouslySetInnerHTML={{ __html: error ? error : 'Please review the purchase and payment details. If everything looks correct, click Yes to complete your purchase. If you need to make any changes or cancel, click No.' }} /></div>
                                </div>
                            </div>

                        </div>
                    </div>

                    <div className="bg-gray-50 px-4 py-3 flex flex-row gap-3">
                        <div className='grow'></div>

                        <button
                                type="button"
                                data-autofocus
                                disabled={loading}
                                onClick={loading ? undefined : () => {
                                    if (setDialog) {
                                        setDialog(false);
                                    }
                                }}
                                className={`disabled:bg-gray-500 inline-flex w-[80px] justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 ${ !error && 'hidden'}`}
                            >
                                OK
                            </button>

                        <div className={`flex flex-row gap-3 ${error && 'hidden'}`}>
                            <button
                                className='disabled:bg-gray-500 w-[80px] flex flex-row items-center justify-center bg-blue-600 px-3 py-1 text-sm font-medium rounded-md text-white shadow-sm hover:bg-blue-500 active:bg-blue-700'
                                disabled={loading}
                                onClick={onClickYes}>
                                {loading && <Spinner className='inline mr-2' />}Yes
                            </button>

                            <button
                                type="button"
                                data-autofocus
                                disabled={loading}
                                onClick={loading ? undefined : () => {
                                    if (setDialog) {
                                        setDialog(false);
                                    }
                                }}
                                className="disabled:bg-gray-500 inline-flex w-[80px] justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                            >
                                No
                            </button>
                        </div>

                    </div>
                </DialogPanel>
            </div>
        </div>
    </Dialog>)
}
