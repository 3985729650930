import { FaSearch } from "react-icons/fa";
import Footer from "../../components/footer";
import Header from "../../components/header";

export default function Tutorials() {
    return (
        <>
            <div className="flex flex-col h-screen">
                <Header className='flex' />

                <div className="flex-1 w-full md:my-10">
                    <div className='mx-auto w-full md:max-w-[1024px] px-5'>

                        <div className='text-center mx-auto w-full md:w-[500px] mb-10'>
                            <h1 className='mt-10 mb-2 text-[30px] leading-tight'>Tutorials</h1>
                            <p>Get started with our service through simple, guided tutorials and make the most of its features.</p>
                        </div>

                        <div className="bg-white rounded-xl p-8">
                            <div className="relative">
                                <input
                                    disabled={true}
                                    type="text"
                                    required
                                    placeholder="Search text here"
                                    className="px-3 h-[50px] block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 placeholder:text-sm"
                                    onChange={(e) => { }}
                                />

                                <span className="absolute inset-y-0 end-0 flex items-center px-5 cursor-pointer text-gray-500" ><FaSearch size={25} /></span>

                            </div>
                        </div>

                        <div className="mt-10 px-8">
                            <ul className="list-disc flex flex-col gap-3">
                                <li>
                                    <a href="/tutorials/how-to-connect-l2tp-client" className="text-blue-500">Configuring <b>L2TP</b> Client on MikroTik</a>
                                </li>

                                <li>
                                    <a href="/tutorials/how-to-connect-sstp-client" className="text-blue-500">Configuring <b>SSTP</b> Client on MikroTik</a>
                                </li>
                                <li>
                                    <a href="/tutorials/how-to-connect-ovpn-client" className="text-blue-500">Configuring <b>OVPN</b> Client on MikroTik</a>
                                </li>
                                <li>
                                    <a href="/tutorials/how-to-remotely-access-mikrotik-using-winbox" className="text-blue-500">How to remotely access MikroTIk using Winbox</a>
                                </li>
                                <li>
                                    <a href="/tutorials/how-to-install-ovpn-client-on-adopisoft" className="text-blue-500">How to install OVPN client on adopisoft</a>
                                </li>

                            </ul>
                        </div>

                    </div>

                </div>

                <Footer />
            </div>
        </>
    )
}
